import React, { useState } from "react";
import {
  CssBaseline,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import GroupAdd from "@mui/icons-material/GroupAdd";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";

import apiClient from "common/apiClientAxios";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import {
  Device,
  DeviceFormMasterData,
  DeviceForm,
  DeviceGroup,
} from "../types";
import { constants } from "../../../common/constants";
import { useDrawerFormStyles } from "common/styles/useDrawerFormStyles";
import TcSelectDropdown from "common/components/TcSelectDropdown";
import TcTextField from "common/components/TcTextField";
import { DeviceGroupType } from "common/enums";
import FormHeader from "common/components/FormHeader";
import SelectGroupDialog from "./SelectGroupDialog";
import { filterDeviceGroup, hasAllElements } from "common/helpers/utils";
import TcSelectWithButtonLastOption from "common/components/TcSelectWithButtonLastOption";
import TabPanel from "common/components/TabPanel";
import ConnectionProfiles from "../connectionProfiles/ConnectionProfiles";

type EditDeviceProps = {
  onClose: () => void;
  onSaveUpdateTable: () => void;
  selectedDevice: Device;
  deviceFormMasterData: DeviceFormMasterData;
  openAddManufacturerForm: () => void;
};

const EditDevice: React.FC<EditDeviceProps> = (props) => {
  const classes = useDrawerFormStyles();
  const dispatch = useDispatch();
  const { groups, deviceTypes, manufacturers, sites } =
    props.deviceFormMasterData;
  const onClose = () => {
    props.onClose();
  };

  const methods = useForm<DeviceForm>({
    defaultValues: {
      ...props.selectedDevice,
      groups: props.selectedDevice.groups?.map((obj) => obj.groupId),
      connections: props.selectedDevice.connections?.map((obj) => obj.deviceId),
      domainRegistered: props.selectedDevice.domainRegistered ?? "No",
    },
    mode: "onChange",
  });
  const {
    handleSubmit,
    getValues,
    formState: { isValid },
    control,
  } = methods;

  const [affectedDynamicGroups, setAffectedDynamicGroups] = useState<
    DeviceGroup[]
  >([]);
  const [submittedDeviceFormData, setSubmittedDeviceFormData] =
    useState<DeviceForm>({
      ...props.selectedDevice,
      groups: props.selectedDevice.groups?.map((obj) => obj.groupId),
      connections: props.selectedDevice.connections?.map((obj) => obj.deviceId),
    });
  const [openSelectGroupDialog, setOpenSelectGroupDialog] = useState(false);

  const onUpdateDevice = async (data: DeviceForm) => {
    setSubmittedDeviceFormData((prevState) => {
      return { ...prevState, connectionProfiles: data.connectionProfiles };
    });
    const dynamicGroups = groups.filter(
      (group) => group.type === DeviceGroupType.DYNAMIC
    );
    //if affected group is same as existing device group then submit form
    const dynamicGroupIds = dynamicGroups.map((grp) => grp.groupId);
    const defaultGroupIds = props.selectedDevice.groups?.map(
      (grp) => grp.groupId
    );

    if (
      dynamicGroupIds.length === defaultGroupIds?.length &&
      hasAllElements(dynamicGroupIds, defaultGroupIds)
    ) {
      submitDevice(defaultGroupIds, {
        ...submittedDeviceFormData,
        connectionProfiles: data.connectionProfiles,
      });
    } else {
      const accessMethods =
        data.connectionProfiles?.map((profile) => profile.protocol) ?? [];
      const filterGroups = filterDeviceGroup(
        dynamicGroups,
        {
          ...submittedDeviceFormData,
          connectionProfiles: data.connectionProfiles,
        },
        accessMethods
      );
      setAffectedDynamicGroups(filterGroups);
      if (filterGroups.length === 0) {
        submitDevice([], {
          ...submittedDeviceFormData,
          connectionProfiles: data.connectionProfiles,
        });
      } else {
        setOpenSelectGroupDialog(true);
      }
    }
  };

  const submitDevice = async (selectedGroups?: string[], data?: DeviceForm) => {
    try {
      const formData = data ?? submittedDeviceFormData;
      //find static groups of device
      const existingStaticGroups = groups.filter(
        (group) =>
          group.type === DeviceGroupType.STATIC &&
          props.selectedDevice.groups
            ?.map((grp) => grp.groupId)
            ?.includes(group.groupId)
      );
      //Check if device fit in filter conditions of group
      const newStaticGroups = filterDeviceGroup(
        existingStaticGroups,
        formData,
        formData.connectionProfiles?.map((profile) => profile.protocol) ?? []
      ).map((obj) => {
        return { groupId: obj.groupId, name: obj.name };
      });
      const newDynamicGroups = groups
        .filter((group) => selectedGroups?.includes(group.groupId))
        .map((obj) => {
          return { groupId: obj.groupId, name: obj.name };
        });
      const selectedType = deviceTypes.find(
        (deviceType) => deviceType.deviceTypeId === formData.typeId
      );
      const selectedManufacturer = manufacturers.find(
        (manufacturer) =>
          manufacturer.manufacturerId === formData.manufacturerId
      );
      const type = selectedType?.name;
      const manufacturerName = selectedManufacturer?.name;
      const site = sites.find((site) => site.siteId === formData.siteId);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      const deviceResponse = await apiClient.put(
        `/devices/${props.selectedDevice.deviceId}`,
        {
          ...formData,
          groups: [...newStaticGroups, ...newDynamicGroups],
          type,
          manufacturerName,
          siteName: site?.name,
          inboundIpAddr: site?.inboundIpAddr,
          timezone: site?.timezone,
        }
      );
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      dispatch(
        setSnackbarToast({
          message: deviceResponse.data.meta.message,
          open: true,
          severity: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
    props.onSaveUpdateTable();
  };

  const handleCreateManufacturer = () => {
    props.openAddManufacturerForm();
  };

  const [tabValue, setTabValue] = useState("1");

  const onNextClick = handleSubmit(async (data: DeviceForm) => {
    setSubmittedDeviceFormData((prevState) => {
      return { ...prevState, ...data };
    });
    setTabValue("2");
  });

  const onBackClick = () => {
    setTabValue("1");
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    const deviceFormData = getValues();
    setSubmittedDeviceFormData((prevState) => {
      return { ...prevState, ...deviceFormData };
    });
    setTabValue(newValue);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <FormHeader
        title={`Edit Device: ${props.selectedDevice.name}`}
        onClose={onClose}
      ></FormHeader>
      <TabContext value={tabValue}>
        <TabList
          centered
          onChange={handleChange}
          variant="fullWidth"
          textColor="primary"
          indicatorColor="secondary"
        >
          <Tab label="Device" value={"1"} />
          <Tab label="Connection Profiles" value={"2"} disabled={!isValid} />
        </TabList>
        <TabPanel value="1">
          <FormProvider {...methods}>
            <form noValidate>
              <Grid container spacing={2} className={classes.formContainer}>
                <Grid item xs={6}>
                  <TcTextField
                    name="name"
                    label="Name *"
                    rules={{
                      required: "Name is required",
                      pattern: {
                        value: constants.NAME_REGEX,
                        message: "Invalid name",
                      },
                    }}
                  ></TcTextField>
                </Grid>
                <Grid item xs={6}>
                  <TcTextField
                    name="alias"
                    label="Alias"
                    rules={{
                      pattern: {
                        value: constants.NAME_REGEX,
                        message: "Invalid Alias",
                      },
                    }}
                  ></TcTextField>
                </Grid>
                <Grid item xs={6}>
                  <TcSelectWithButtonLastOption
                    name="manufacturerId"
                    label="Manufacturer"
                    optionKey="manufacturerId"
                    options={manufacturers}
                    optionLabel="name"
                    buttonIcon={<GroupAdd />}
                    buttonLabel="Add Manufacturer"
                    optionButtonClick={handleCreateManufacturer}
                  ></TcSelectWithButtonLastOption>
                </Grid>
                <Grid item xs={6}>
                  <TcSelectDropdown
                    name="siteId"
                    label="Site *"
                    options={sites}
                    optionKey="siteId"
                    optionLabel="name"
                    rules={{
                      required: "Site is required",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TcSelectDropdown
                    name="typeId"
                    label="Type *"
                    options={deviceTypes}
                    optionKey="deviceTypeId"
                    optionLabel="name"
                    rules={{
                      required: "Type is required",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <Grid container alignItems="center">
                      <Grid item>
                        <FormLabel component="legend">
                          Domain Registered:{" "}
                        </FormLabel>
                      </Grid>
                      <Grid item sx={{ paddingLeft: 1 }}>
                        <Controller
                          name="domainRegistered"
                          control={control}
                          render={({ field }) => (
                            <RadioGroup {...field}>
                              <Grid container direction="row">
                                <Grid item>
                                  <FormControlLabel
                                    value={"Yes"}
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                </Grid>
                                <Grid item>
                                  <FormControlLabel
                                    value={"No"}
                                    control={<Radio />}
                                    label="No"
                                  />
                                </Grid>
                              </Grid>
                            </RadioGroup>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.bottomDivider}>
                <Grid item xs={2}>
                  <Button
                    size="small"
                    type="submit"
                    disabled={!isValid}
                    onClick={onNextClick}
                    fullWidth
                    variant="contained"
                    color="info"
                  >
                    {"Next"}
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    size="small"
                    fullWidth
                    variant="outlined"
                    color="info"
                    onClick={onClose}
                  >
                    {"Cancel"}
                  </Button>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
            </form>
          </FormProvider>
        </TabPanel>
        <TabPanel value="2">
          <ConnectionProfiles
            onSaveProfile={onUpdateDevice}
            deviceTypes={props.deviceFormMasterData.deviceTypes}
            manufacturers={props.deviceFormMasterData.manufacturers}
            onBackClick={onBackClick}
            onCancelClick={onClose}
            device={getValues()}
            connectionProfiles={props.selectedDevice.connectionProfiles}
          ></ConnectionProfiles>
        </TabPanel>
      </TabContext>
      {openSelectGroupDialog && (
        <SelectGroupDialog
          groups={affectedDynamicGroups}
          onCancel={() => setOpenSelectGroupDialog(false)}
          onSaveGroup={(selectedGroups) => submitDevice(selectedGroups)}
          open={openSelectGroupDialog}
        ></SelectGroupDialog>
      )}
    </React.Fragment>
  );
};

export default EditDevice;
