import React, { useCallback, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CssBaseline,
  Grid,
  Typography,
} from "@mui/material";

import { DeviceFormMasterData, DevicesGroupFilterData } from "../types";
import { useFilterFormStyles } from "common/styles/useFilterFormStyles";
import TcCheckboxInput from "common/components/TcCheckboxInput";
import { CompareValues, DeviceStatus } from "common/enums";
import { isEndUser } from "common/helpers/utils";

const deviceStatuses = [
  { id: DeviceStatus.ONLINE, label: DeviceStatus.ONLINE },
  { id: DeviceStatus.OFFLINE, label: DeviceStatus.OFFLINE },
];

type FilterProps = {
  onApplyFilter: (formData: DevicesGroupFilterData) => void;
  filters: DevicesGroupFilterData;
  handleClearFilter: () => void;
  handleCancelFilter: () => void;
  deviceFormMasterData: DeviceFormMasterData;
  isShowCancel?: boolean;
  isResetForm?: boolean;
};

const DevicesFilter: React.FC<FilterProps> = (filterProps) => {
  const classes = useFilterFormStyles();
  const {
    deviceTypes,
    manufacturers,
    sites,
    accessMethods,
    groups,
    owners,
    operators,
  } = filterProps.deviceFormMasterData;
  const { isShowCancel = true } = filterProps;
  const [statuses] = useState<
    {
      id: string;
      label: string;
    }[]
  >(deviceStatuses);
  const methods = useForm({
    defaultValues: filterProps.filters,
  });
  const { handleSubmit, reset } = methods;
  const onSubmit = handleSubmit(async (data: DevicesGroupFilterData) => {
    filterProps.onApplyFilter(data);
  });

  const resetForm = useCallback(() => {
    reset({
      accessMethods: [],
      manufacturers: [],
      types: [],
      statuses: [],
      sites: [],
      groups: [],
      owners: [],
      operators: [],
    });
  }, [reset]);

  const onClear = () => {
    resetForm();
    filterProps.handleClearFilter();
  };

  const onCancel = () => {
    resetForm();
    filterProps.handleCancelFilter();
  };

  useEffect(() => {
    if (filterProps.isResetForm) {
      resetForm();
    }
  }, [filterProps.isResetForm, resetForm]);

  React.useEffect(() => {
    reset(filterProps.filters);
  }, [filterProps.filters, reset]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Typography variant="subtitle1">
        <strong>Filters</strong>
      </Typography>

      <FormProvider {...methods}>
        <div className={classes.root}>
          {isShowCancel && !isEndUser() && (
            <Card key={1} className={classes.longCard} variant="outlined">
              <CardHeader
                subheader={
                  <Typography variant="subtitle1">
                    <strong>Groups</strong>
                  </Typography>
                }
                className={classes.cardHeader}
              ></CardHeader>
              <CardContent className={classes.cardContent}>
                <TcCheckboxInput
                  name="groups"
                  optionKey="groupId"
                  optionLabel="name"
                  options={groups}
                  className="custom-checkbox-font-size"
                />
              </CardContent>
            </Card>
          )}

          <Card key={2} className={classes.shortestCard} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Protocol</strong>
                </Typography>
              }
              className={classes.cardHeader}
            ></CardHeader>
            <CardContent>
              <TcCheckboxInput
                name="accessMethods"
                optionKey="name"
                optionLabel="name"
                options={accessMethods}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          <Card key={4} className={classes.shortCard} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Type</strong>
                </Typography>
              }
              className={classes.cardHeader}
            ></CardHeader>
            <CardContent>
              <TcCheckboxInput
                name="types"
                optionKey="deviceTypeId"
                optionLabel="name"
                options={deviceTypes}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          <Card key={5} className={classes.card} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Manufacturer</strong>
                </Typography>
              }
              className={classes.cardHeader}
            ></CardHeader>

            <CardContent className={classes.cardContent}>
              <TcCheckboxInput
                name="manufacturers"
                optionKey="manufacturerId"
                optionLabel="name"
                options={manufacturers}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          <Card key={7} className={classes.shortestCard} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Status</strong>
                </Typography>
              }
              className={classes.cardHeader}
            ></CardHeader>
            <CardContent>
              <TcCheckboxInput
                name="statuses"
                optionKey="id"
                optionLabel="label"
                options={statuses}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          {!(
            isEndUser() &&
            process.env.REACT_APP_PORTAL_RESTRICT_SITE?.toLowerCase() ===
              CompareValues.TRUE
          ) && (
            <Card key={6} className={classes.addressCard} variant="outlined">
              <CardHeader
                subheader={
                  <Typography variant="subtitle1">
                    <strong>Site</strong>
                  </Typography>
                }
                className={classes.cardHeader}
              ></CardHeader>
              <CardContent className={classes.cardContent}>
                <TcCheckboxInput
                  name="sites"
                  optionKey="siteId"
                  optionLabel="name"
                  options={sites}
                  className="custom-checkbox-font-size"
                />
              </CardContent>
            </Card>
          )}
          <Card key={8} className={classes.card} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Owner</strong>
                </Typography>
              }
              className={classes.cardHeader}
            ></CardHeader>
            <CardContent className={classes.cardContent}>
              <TcCheckboxInput
                name="owners"
                optionKey="ownerId"
                optionLabel="name"
                options={owners}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          <Card key={9} className={classes.card} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Operator</strong>
                </Typography>
              }
              className={classes.cardHeader}
            ></CardHeader>
            <CardContent className={classes.cardContent}>
              <TcCheckboxInput
                name="operators"
                optionKey="operatorId"
                optionLabel="name"
                options={operators}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
        </div>
        <Grid container spacing={2} className={classes.bottomDivider}>
          <Grid item xs={2}>
            <Button
              size="small"
              type="submit"
              onClick={onSubmit}
              fullWidth
              variant="contained"
              color="info"
            >
              {"Apply All"}
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              size="small"
              fullWidth
              variant="outlined"
              color="info"
              onClick={onClear}
            >
              {"Clear All"}
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              size="small"
              fullWidth
              variant="outlined"
              color="info"
              onClick={onCancel}
            >
              {"Cancel"}
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </React.Fragment>
  );
};
export default DevicesFilter;
