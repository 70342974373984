import React, { useState } from "react";
import {
  Avatar,
  Box,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { makeStyles } from "@mui/styles";
import { styled, Theme } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import assets from "../../assets";
import AccountSettingsDialogue from "pages/settings/AccountSettings/AccountSettingsDialogue";
import useAuth from "pages/login/authService";
import { constants } from "../constants";

const drawerWidth = 247;
const useStyles = makeStyles((theme: Theme) => ({
  toolbarIcons: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
  searchIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField: {
    background: "white",
    border: "1px solid white",
    borderRadius: theme.spacing(1),
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
    },
  },
}));

type TopbarProps = {
  open: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
};
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.primary.main,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const TopBar = ({ open, handleDrawerOpen, handleDrawerClose }: TopbarProps) => {
  const classes = useStyles();
  const { logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [aboutAnchorEl, setAboutAnchorEl] = useState<null | HTMLElement>(null);
  const [openSettingsDialogue, setOpenSettingsDialogue] = React.useState(false);

  const onCloseSettingsDialogue = () => {
    setOpenSettingsDialogue(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAccountSettingsClick = () => {
    setOpenSettingsDialogue(true);
    setAnchorEl(null);
  };
  const handleLogoutClick = () => {
    logout();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resumeToAdmin = () => {
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("login_with");
    sessionStorage.removeItem("remote_access");
    window.close();
  };
  const handleAboutClick = (event: React.MouseEvent<HTMLElement>) => {
    setAboutAnchorEl(event.currentTarget);
  };

  const handleAboutClose = () => {
    setAboutAnchorEl(null);
  };

  return (
    <>
      <AppBar position="fixed" open={open} elevation={0}>
        <Toolbar disableGutters sx={{ paddingLeft: 0.5 }}>
          <Stack
            sx={{ width: "100%" }}
            direction="row"
            justifyContent="left"
            alignItems={"center"}
            spacing={1}
          >
            {!open ? (
              <>
                <Avatar
                  sx={{ cursor: "pointer" }}
                  src={assets.images.circleLogo}
                  onClick={handleDrawerOpen}
                />
                <IconButton
                  onClick={handleDrawerOpen}
                  size="large"
                  color="inherit"
                >
                  <KeyboardDoubleArrowRightIcon />
                </IconButton>
              </>
            ) : (
              <IconButton
                onClick={handleDrawerClose}
                size="large"
                color="inherit"
              >
                <KeyboardDoubleArrowLeftIcon />
              </IconButton>
            )}
          </Stack>
          <div className={classes.toolbarIcons}>
            {sessionStorage.getItem("access_token") && (
              <Box sx={{ whiteSpace: "nowrap", paddingRight: 2 }}>
                <Link
                  component="button"
                  sx={{
                    color: (theme: Theme) => theme.palette.info.main,
                    textDecorationColor: (theme: Theme) =>
                      theme.palette.info.main,
                  }}
                  onClick={() => resumeToAdmin()}
                >
                  Resume to Admin
                </Link>
              </Box>
            )}
            <IconButton color="inherit" onClick={handleClick}>
              <PersonIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Popover
        id="user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          padding: 2,
        }}
      >
        <List>
          <ListItemButton onClick={handleAccountSettingsClick}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Account Settings" />
          </ListItemButton>
          <ListItemButton onClick={handleLogoutClick}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
          <ListItemButton onClick={handleAboutClick}>
            <ListItemIcon>
              <InfoOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="About" />
          </ListItemButton>
        </List>
      </Popover>
      <Popover
        id="about-popover"
        anchorEl={aboutAnchorEl}
        open={Boolean(aboutAnchorEl)}
        onClose={handleAboutClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>
          Version: {<strong>{constants.APP_VERSION}</strong>}
        </Typography>
      </Popover>
      {openSettingsDialogue && (
        <AccountSettingsDialogue
          onCancel={onCloseSettingsDialogue}
          open={openSettingsDialogue}
        />
      )}
    </>
  );
};

export default TopBar;
