import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";

import {
  Device,
  DeviceConnectionProfileForm,
  DeviceFormMasterData,
  DeviceGroup,
  DeviceGroupNameForm,
  DevicesGroupFilterData,
  UpdateDeviceGroup,
} from "../types";
import DevicesFilter from "./DevicesFilter";
import { constants } from "common/constants";
import { useContentStyles } from "common/styles/useContentStyles";
import CustomToolbar from "common/components/CustomToolbar";
import AddDeviceGroup from "../groups/AddDeviceGroup";
import { DeviceGroupType } from "common/enums";
import DeviceTypeDialog from "../groups/DeviceTypeDialog";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import apiClient from "common/apiClientAxios";
import { getFilteredDeviceRows, hasPermission } from "common/helpers/utils";
import GroupType from "./GroupType";
import NoRowsOverlay from "common/components/NoRowsOverlay";
import { useTheme } from "@mui/material/styles";
import DevicesFilterChipDisplay from "./DevicesFilterChipDisplay";

const EditDeviceGroupTable: React.FC = () => {
  const classes = useContentStyles();
  const [rows, setRows] = useState<Device[]>([]);
  const [devices, setDevices] = useState<Device[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { deviceGroup } =
    (location.state as { deviceGroup?: DeviceGroup }) || {};
  //if location state has issues then redirect to list page
  useEffect(() => {
    if (!deviceGroup) {
      dispatch(
        setSnackbarToast({
          message: "Please select group from list",
          open: true,
          severity: "error",
        })
      );
      navigate("/portal/devices/groups");
    }
  }, [dispatch, navigate, deviceGroup]);

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [deviceFormMasterData, setDeviceFormMasterData] =
    useState<DeviceFormMasterData>({
      accessMethods: [],
      deviceTypes: [],
      groups: [],
      manufacturers: [],
      sites: [],
      owners: [],
      operators: [],
    });
  const isRunEffect = useRef(true);
  const [openFilter, setOpenFilter] = useState(true);
  const [filters, setFilters] = useState<DevicesGroupFilterData>({
    accessMethods: deviceGroup?.accessMethods ?? [],
    manufacturers: deviceGroup?.manufacturers ?? [],
    types: deviceGroup?.deviceTypes ?? [],
    statuses: deviceGroup?.deviceStatuses ?? [],
    sites: deviceGroup?.sites ?? [],
    groups: [],
    owners: deviceGroup?.owners ?? [],
    operators: deviceGroup?.operators ?? [],
  });

  const handleApplyFilter = (filterValues: DevicesGroupFilterData) => {
    setFilters(filterValues);
    const updatedRows = getFilteredDeviceRows(devices, filterValues);
    setRows(updatedRows);
    //if type is Dynamic then select all rows
    if (groupTypeSwitchValue === DeviceGroupType.DYNAMIC) {
      setRowSelectionModel(updatedRows.map((device) => device.deviceId));
    }
    setOpenFilter(false);
  };

  const applyInitialFilter = useCallback(
    (
      formMasters: DeviceFormMasterData,
      groupDevices: Device[],
      allDevices: Device[]
    ) => {
      setDeviceFormMasterData(formMasters);
      const updatedRows = getFilteredDeviceRows(allDevices, filters);
      setRows(updatedRows);
      setRowSelectionModel(groupDevices.map((device) => device.deviceId));
      setSelectedDevices(groupDevices);
    },
    [filters]
  );

  const getDeviceFormMasterData = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      const formMasterResponse = await apiClient.get(
        "devices/device-form-look-up-data"
      );
      const formMasters = formMasterResponse.data.data as DeviceFormMasterData;
      const devicesResponse = await apiClient.get(
        `/devices?groupId=${deviceGroup?.groupId}`
      );
      const allDevicesResponse = await apiClient.get("devices");
      setDevices(allDevicesResponse.data.data.devices);
      applyInitialFilter(
        formMasters,
        devicesResponse.data.data as Device[],
        allDevicesResponse.data.data.devices
      );
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  }, [applyInitialFilter, deviceGroup?.groupId, dispatch]);

  const updateLastAccess = useCallback(async () => {
    await apiClient.put(
      `devices-groups/${deviceGroup?.groupId}/updateLastAccess`
    );
  }, [deviceGroup?.groupId]);

  useEffect(() => {
    if (isRunEffect.current) {
      getDeviceFormMasterData();
      updateLastAccess();
      isRunEffect.current = false;
    }
  }, [getDeviceFormMasterData, isRunEffect, updateLastAccess]);

  const columns = useMemo<GridColDef[]>(
    () => [
      { field: "name", type: "string", headerName: "Device Name", width: 250 },
      {
        field: "alias",
        type: "string",
        headerName: "Alias(es)",
        flex: 1,
      },
      {
        field: "groups",
        type: "string",
        headerName: "Group(s)",
        flex: 1,
        valueGetter: (value) => {
          const groups = (value || []) as unknown as DeviceGroup[];
          return groups.map((obj) => obj.name).join(", ");
        },
      },
      {
        field: "siteName",
        type: "string",
        headerName: "Site",
        flex: 1,
      },
      { field: "type", type: "string", headerName: "Type", flex: 1 },
      {
        field: "accessMethods",
        type: "string",
        headerName: "Access Methods",
        flex: 1,
        renderCell: (params) => (
          <React.Fragment>
            {params.row.accessMethods.map(
              (profile: DeviceConnectionProfileForm, index: number) => (
                <React.Fragment key={index}>
                  {profile.name}
                  {index < params.row.accessMethods.length - 1 && ", "}
                </React.Fragment>
              )
            )}
            {params.row.accessMethods.length === 0 && "-"}
          </React.Fragment>
        ),
      },
      {
        field: "manufacturerName",
        type: "string",
        headerName: "Manufacturer",
        flex: 1,
      },
    ],
    []
  );

  const handleFilterClick = () => {
    setOpenFilter(true);
  };

  const handleClearFilter = () => {
    setRows(devices);
  };

  const handleCancelFilter = () => {
    setOpenFilter(false);
    navigate("/portal/devices/groups");
  };

  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [deviceGroupName, setDeviceGroupName] = useState("");

  const onSaveGroup = (data: DeviceGroupNameForm) => {
    if (rowSelectionModel.length > 0) {
      setDeviceGroupName(data.name);
      setOpenDeviceTypeDialogue(true);
    } else {
      dispatch(
        setSnackbarToast({
          message: "Please select the devices",
          open: true,
          severity: "error",
        })
      );
    }
  };

  const [openDeviceTypeDialogue, setOpenDeviceTypeDialogue] = useState(false);

  const onConfirmType = async () => {
    const type = groupTypeSwitchValue;
    const updatedArray = rowSelectionModel as string[];
    const originalArray = selectedDevices.map((device) => device.deviceId);
    const newAddedDevices = updatedArray.filter(
      (updatedObj) =>
        !originalArray.some((originalObj) => originalObj === updatedObj)
    );
    const removedDevices = originalArray.filter(
      (originalObj) =>
        !updatedArray.some((updatedObj) => updatedObj === originalObj)
    );
    let existingDevices: string[] = [];
    if (deviceGroup && deviceGroup.name !== deviceGroupName)
      existingDevices = originalArray.filter((element) =>
        updatedArray.includes(element)
      );

    const updatedDeviceGroup: UpdateDeviceGroup = {
      name: deviceGroupName,
      type,
      devicesCount: rowSelectionModel.length,
      accessMethods: filters.accessMethods,
      devices: rowSelectionModel as string[],
      deviceStatuses: filters.statuses,
      deviceTypes: filters.types,
      manufacturers: filters.manufacturers,
      sites: filters.sites,
      newAddedDevices,
      removedDevices,
      existingDevices,
    };
    //update group
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      const groupResponse = await apiClient.put(
        `/devices-groups/${deviceGroup?.groupId}`,
        updatedDeviceGroup
      );
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      dispatch(
        setSnackbarToast({
          message: groupResponse.data.meta.message,
          open: true,
          severity: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
    navigate("/portal/devices/groups");
  };

  const [groupTypeSwitchValue, setGroupTypeSwitchValue] = useState(
    deviceGroup?.type ?? DeviceGroupType.STATIC
  );
  const onCancelGroupType = (type: DeviceGroupType) => {
    setGroupTypeSwitchValue(type);
  };

  const onConfirmSaveGroupType = (type: DeviceGroupType) => {
    if (type === DeviceGroupType.DYNAMIC) {
      const updatedRowsSelection = rows.map((row) => row.deviceId);
      setRowSelectionModel(updatedRowsSelection);
    }
    setGroupTypeSwitchValue(type);
  };
  const theme = useTheme();
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="none" color="inherit">
            Devices
          </Link>
          <RouterLink
            to="/portal/devices/groups"
            style={{
              color: theme.palette.text.secondary,
            }}
          >
            Groups
          </RouterLink>
          <Typography variant="body1">{deviceGroup?.name}</Typography>
        </Breadcrumbs>
      </div>
      <Card elevation={0} className={classes.contentSection}>
        {
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <AddDeviceGroup
                isEdit
                onCancelGroup={() => {
                  navigate("/portal/devices/groups");
                }}
                onSaveGroup={onSaveGroup}
                formData={{ name: deviceGroup?.name ?? "" }}
              ></AddDeviceGroup>
            </Grid>
            <Grid item xs={3} alignContent={"center"}>
              <GroupType
                rows={rows}
                rowSelectionModel={rowSelectionModel as string[]}
                onCancel={onCancelGroupType}
                defaultGroupType={groupTypeSwitchValue as DeviceGroupType}
                onConfirmSave={onConfirmSaveGroupType}
                filters={filters}
                isFilterSubmitted={true}
              ></GroupType>
            </Grid>
          </Grid>
        }
        {openFilter && (
          <Card elevation={0} className={classes.contentSection}>
            <DevicesFilter
              onApplyFilter={handleApplyFilter}
              handleCancelFilter={handleCancelFilter}
              handleClearFilter={handleClearFilter}
              filters={filters}
              isShowCancel={false}
              isResetForm={false}
              deviceFormMasterData={deviceFormMasterData}
            />
          </Card>
        )}
        <DataGrid
          columns={columns}
          rows={rows}
          getRowId={(row) => row.deviceId}
          pageSizeOptions={constants.PAGE_SIZE_OPTIONS}
          initialState={{
            pagination: { paginationModel: constants.PAGINATION_MODEL },
            columns: {
              columnVisibilityModel: {
                alias: false,
                type: false,
                manufacturerName: false,
              },
            },
          }}
          slots={{
            toolbar: () => (
              <>
                <CustomToolbar handleFilterClick={handleFilterClick} />
                {!openFilter && (
                  <DevicesFilterChipDisplay
                    deviceFormMasterData={deviceFormMasterData}
                    filters={filters}
                    handleUpdatedFilter={handleApplyFilter}
                    openFilter={() => setOpenFilter(true)}
                  />
                )}
              </>
            ),
            noRowsOverlay: () => (
              <NoRowsOverlay
                hasAccess={hasPermission("devices.summary", "read")}
                name="Devices"
              />
            ),
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          autoHeight
          sx={{
            marginTop: 1,
            "& .MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "600",
            },
          }}
          getRowClassName={(params) =>
            params.row.type.toLowerCase() === constants.DEVICE_TYPE_GATEWAY
              ? classes.rowColor
              : ""
          }
          isRowSelectable={() =>
            groupTypeSwitchValue === DeviceGroupType.STATIC
          }
          checkboxSelection={true}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          disableVirtualization
        />
        {openDeviceTypeDialogue && (
          <DeviceTypeDialog
            dialogContent={
              groupTypeSwitchValue === DeviceGroupType.STATIC
                ? constants.STATIC_GROUP_UPDATE_CONFIRM_MESSAGE
                : constants.DYNAMIC_GROUP_UPDATE_CONFIRM_MESSAGE
            }
            onConfirmType={onConfirmType}
            open={openDeviceTypeDialogue}
            isEdit={true}
            onCancel={() => {
              setOpenDeviceTypeDialogue(false);
            }}
          ></DeviceTypeDialog>
        )}
      </Card>
    </React.Fragment>
  );
};
export default EditDeviceGroupTable;
