import React, { Fragment, useState, useEffect, useCallback } from "react";
import {
  Button,
  Grid,
  IconButton,
  CardContent,
  Card,
  Box,
  Tooltip,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  SelectChangeEvent,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  DragIndicator as DragIcon,
} from "@mui/icons-material";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { get } from "lodash";
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { useDispatch } from "react-redux";

import apiClient from "common/apiClientAxios";
import {
  DeviceConnectionProfileForm,
  DeviceManufacturer,
  DeviceType,
  DeviceForm,
} from "../types";
import { constants } from "common/constants";
import TcTextField from "common/components/TcTextField";
import {
  AccessProfile,
  AuthenticationType,
  AuthMethod,
  DefaultPort,
  DomainRegistered,
  Protocol,
} from "common/enums";
import { generateUniqueName, isProfileNameExists } from "common/helpers/utils";
import TcSelectWithStringArray from "common/components/TcSelectWithStringArray";
import TcPasswordTextField from "common/components/TcPasswordTextField";
import FileUpload from "common/components/FileUpload";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";

type AddConnectionProfileProps = {
  device: DeviceForm;
  manufacturers: DeviceManufacturer[];
  deviceTypes: DeviceType[];
  profiles: DeviceConnectionProfileForm[];
  index: number;
  onDeleteProfile: () => void;
  onSaveProfile: () => void;
  currentProfile: DeviceConnectionProfileForm;
};

// SortableItem component
const SortableItem = ({
  id,
  children,
}: {
  id: string;
  children: React.ReactNode;
}) => {
  const { attributes, listeners, setNodeRef, transform } = useSortable({ id });

  const style = {
    transform: `translate3d(${transform?.x ?? 0}px, ${transform?.y ?? 0}px, 0)`,
    marginBottom: "8px",
  };

  return (
    <Box style={style} mb={2} mt={2} pb={2} display="flex" alignItems="center">
      <IconButton ref={setNodeRef} {...attributes} {...listeners}>
        <DragIcon />
      </IconButton>
      {children}
    </Box>
  );
};

const AddConnectionProfile: React.FC<AddConnectionProfileProps> = ({
  device,
  manufacturers,
  deviceTypes,
  profiles,
  index,
  onDeleteProfile,
  onSaveProfile,
  currentProfile,
}) => {
  const {
    setValue,
    control,
    watch,
    formState: { errors, isValid, dirtyFields, isDirty },
  } = useFormContext();

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: `connectionProfiles.${index}.urls`,
  });
  const dispatch = useDispatch();
  const [selectedProtocol, setSelectedProtocol] = useState(
    currentProfile?.protocol ?? ""
  );
  const hostnameType = watch(`connectionProfiles.${index}.hostnameType`);
  const [selectedLocalAccessProfile, setLocalAccessProfile] = useState<string>(
    currentProfile?.localAccessProfile ?? AccessProfile.GUEST
  );
  const [selectedDomain, setDomain] = useState<string>(
    currentProfile?.domainRegistered ?? DomainRegistered.NO
  );
  const authMethod = watch(`connectionProfiles.${index}.authenticationMethod`);
  const [selectedAuthType, setAuthType] = useState<string>(
    currentProfile?.authType ?? AuthenticationType.MANUAL
  );
  const selectedType = deviceTypes.find(
    (deviceType) => deviceType.deviceTypeId === device.typeId
  );
  const selectedManufacturer = manufacturers.find(
    (manufacturer) => manufacturer.manufacturerId === device.manufacturerId
  );

  const setProfileName = useCallback(
    (accessProfile: string, protocol: string, domain: string) => {
      const name =
        domain === DomainRegistered.YES
          ? `${protocol}-${DomainRegistered.REGISTERED}-${accessProfile}`
          : `${protocol}-${accessProfile}`;
      const existingNames =
        profiles
          ?.filter((_, prIndex) => index !== prIndex)
          .map((profile) => profile.name) ?? [];

      const uniqueName = generateUniqueName(name, existingNames);
      setValue(`connectionProfiles.${index}.name`, uniqueName, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [index, profiles, setValue]
  );

  const setProtocolDefaultValues = (selectedProtocol: string) => {
    const protocolPortMap: { [key: string]: number } = {
      RDP: DefaultPort.RDP,
      HTTP: DefaultPort.HTTP,
      SSH: DefaultPort.SSH,
      HTTPS: DefaultPort.HTTPS,
      "HTTP/S": DefaultPort.HTTPS,
    };
    if (selectedProtocol) {
      setProfileName(
        selectedLocalAccessProfile,
        selectedProtocol,
        selectedDomain
      );
      setValue(
        `connectionProfiles.${index}.port`,
        protocolPortMap[selectedProtocol],
        {
          shouldValidate: true,
          shouldDirty: true,
        }
      );
    }
  };

  const onChangeProtocol = (value: string) => {
    setSelectedProtocol(value);
    setValue(`connectionProfiles.${index}.protocol`, value);
    setProtocolDefaultValues(value);
  };

  const onChangeAccessProfile = (value: string) => {
    setLocalAccessProfile(value);
    setValue(`connectionProfiles.${index}.localAccessProfile`, value);
    setProfileName(value, selectedProtocol, selectedDomain);
  };

  const onChangeDomain = (value: string) => {
    setDomain(value);
    setValue(`connectionProfiles.${index}.domainRegistered`, value);
    setProfileName(selectedLocalAccessProfile, selectedProtocol, value);
  };

  const onChangeAuthType = (value: string) => {
    setAuthType(value);
    setValue(`connectionProfiles.${index}.authType`, value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  useEffect(() => {
    if (selectedProtocol.includes(Protocol.HTTP) && fields.length === 0) {
      append("");
    }
  }, [selectedProtocol, hostnameType, append, fields.length]);

  const getProtocolOptions = () => {
    if (selectedType?.name?.toLowerCase() === "gateway") {
      return selectedManufacturer?.name?.toLowerCase() === "operant"
        ? constants.CONNECTION_PROFILE_PROTOCOLS_GATEWAY
        : constants.CONNECTION_PROFILE_PROTOCOLS_GATEWAY_CONFIG;
    } else {
      return constants.CONNECTION_PROFILE_PROTOCOLS;
    }
  };

  const getFontStyle = (name: string) => {
    return get(dirtyFields, name) ? "" : "italic";
  };

  const handleSaveProfile = () => {
    if (selectedAuthType !== AuthenticationType.PROFILE) {
      setValue(`connectionProfiles.${index}.username`, "");
      setValue(`connectionProfiles.${index}.password`, "");
      setValue(`connectionProfiles.${index}.authenticationMethod`, "");
    }
    onSaveProfile();
  };

  const validateURL = (value: string) => {
    // Disallowed protocols
    const disallowedProtocols = [
      /^file:\/\//,
      /^ftp:\/\//,
      /^ftps:\/\//,
      /^mailto:/,
    ];

    const disallowedCredentialPatterns = [/:\/\/[^/]+@/, /^[^@]+@/];

    if (disallowedProtocols.some((pattern) => pattern.test(value))) {
      return "Invalid protocol. Disallowed protocols are file://, ftp://, ftps://, and mailto://";
    }

    if (disallowedCredentialPatterns.some((pattern) => pattern.test(value))) {
      return "Invalid URL.";
    }

    try {
      // Attempt to create a new URL object, which will throw an error if the URL is invalid
      new URL(value);
    } catch (err) {
      return "Invalid URL format";
    }

    return true;
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  );

  const handleFileUploadClick = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      dispatch(setLoader({ loaderMessage: "Please wait", openLoader: true }));
      const filesResponse = await apiClient.post("uploads/key-file", formData);
      const responseData = filesResponse.data.data;

      dispatch(setLoader({ loaderMessage: "Please wait", openLoader: false }));
      setValue(`connectionProfiles.${index}.keyFile`, responseData.filePath, {
        shouldDirty: true,
        shouldValidate: true,
      });
    } catch (error: any) {
      dispatch(setLoader({ loaderMessage: "Please wait", openLoader: false }));
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({ message: errorData, open: true, severity: "error" })
      );
    }
  };

  return (
    <Fragment>
      <Card variant="outlined" sx={{ mr: 1 }}>
        <CardContent sx={{ padding: 1, mt: 2 }}>
          <Grid container spacing={2} textAlign={"center"}>
            <Grid item xs={6}>
              <FormControl component="fieldset">
                <Grid container alignItems="center">
                  <Grid item>
                    <FormLabel component="legend">
                      Domain Registered:{" "}
                    </FormLabel>
                  </Grid>
                  <Grid item sx={{ paddingLeft: 1 }}>
                    <Controller
                      name="domainRegistered"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          {...field}
                          value={selectedDomain}
                          onChange={(event) =>
                            onChangeDomain(event.target.value)
                          }
                        >
                          <Grid container direction="row">
                            <Grid item>
                              <FormControlLabel
                                value={"Yes"}
                                control={<Radio />}
                                label="Yes"
                              />
                            </Grid>
                            <Grid item>
                              <FormControlLabel
                                value={"No"}
                                control={<Radio />}
                                label="No"
                              />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      )}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                variant="outlined"
                error={Boolean(
                  errors[`connectionProfiles.${index}.localAccessProfile`]
                )}
              >
                <InputLabel size="small">{"Local Access Profile *"}</InputLabel>
                <Controller
                  name={`connectionProfiles.${index}.localAccessProfile`}
                  control={control}
                  rules={{
                    required: "Local Access Profile is required",
                  }}
                  render={({}) => (
                    <Select
                      data-testid={`connectionProfiles.${index}.localAccessProfile`}
                      label="Local Access Profile *"
                      size="small"
                      value={selectedLocalAccessProfile}
                      onChange={(event: SelectChangeEvent) =>
                        onChangeAccessProfile(event.target.value)
                      }
                    >
                      {constants.CONNECTION_PROFILE_ACCESS_PROFILES.map(
                        (option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  )}
                />
                {Boolean(
                  get(errors, `connectionProfiles.${index}.localAccessProfile`)
                ) && (
                  <FormHelperText>
                    {get(
                      errors,
                      `connectionProfiles.${index}.localAccessProfile`
                    )
                      ? (get(
                          errors,
                          `connectionProfiles.${index}.localAccessProfile`
                        )?.message as unknown as string)
                      : ""}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                variant="outlined"
                error={Boolean(errors[`connectionProfiles.${index}.protocol`])}
              >
                <InputLabel size="small">{"Protocol *"}</InputLabel>
                <Controller
                  name={`connectionProfiles.${index}.protocol`}
                  control={control}
                  rules={{ required: "Protocol is required" }}
                  render={({}) => (
                    <Select
                      data-testid={`connectionProfiles.${index}.protocol`}
                      label="Protocol *"
                      size="small"
                      value={selectedProtocol}
                      onChange={(event: SelectChangeEvent) =>
                        onChangeProtocol(event.target.value)
                      }
                    >
                      {getProtocolOptions().map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {Boolean(
                  get(errors, `connectionProfiles.${index}.protocol`)
                ) && (
                  <FormHelperText>
                    {get(errors, `connectionProfiles.${index}.protocol`)
                      ? (get(errors, `connectionProfiles.${index}.protocol`)
                          ?.message as unknown as string)
                      : ""}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name={`connectionProfiles.${index}.name`}
                label="Profile Name *"
                inputProps={{
                  sx: {
                    fontStyle: getFontStyle(`connectionProfiles.${index}.name`),
                  },
                }}
                rules={{
                  required: "Name is required",
                  validate: {
                    nameExists: (value: string) =>
                      isProfileNameExists(value, profiles)
                        ? "This rule name is already used"
                        : true,
                  },
                }}
              />
            </Grid>
            {selectedProtocol.includes(Protocol.HTTP) ? (
              <>
                <Grid item xs={10}>
                  <DndContext
                    onDragEnd={({ active, over }) => {
                      if (active.id !== over?.id) {
                        const oldIndex = fields.findIndex(
                          (field) => field.id === active.id
                        );
                        const newIndex = fields.findIndex(
                          (field) => field.id === over?.id
                        );
                        if (oldIndex !== -1 && newIndex !== -1) {
                          move(oldIndex, newIndex);
                        }
                      }
                    }}
                    sensors={sensors}
                    collisionDetection={closestCenter}
                  >
                    <SortableContext
                      items={fields.map((field) => field.id)}
                      strategy={verticalListSortingStrategy}
                    >
                      {fields.map((field, fieldIndex) => (
                        <SortableItem key={field.id} id={field.id}>
                          <TcTextField
                            name={`connectionProfiles.${index}.urls[${fieldIndex}]`}
                            data-testid={`connectionProfiles.${index}.urls[${fieldIndex}]`}
                            fullWidth
                            label="URL *"
                            rules={{
                              required: "URL is required",
                              validate: validateURL,
                            }}
                            onChange={(event) => {
                              const value = event.target.value;
                              let formattedValue = value;
                              if (
                                /^\d{1,3}(\.\d{1,3}){3}/.test(value) ||
                                value.startsWith("www.")
                              ) {
                                formattedValue = `https://${value}`;
                              }
                              setValue(
                                `connectionProfiles.${index}.urls[${fieldIndex}]`,
                                formattedValue,
                                {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                  shouldTouch: true,
                                }
                              );
                            }}
                          />
                          {fieldIndex !== 0 && (
                            <IconButton
                              onClick={() => remove(fieldIndex)}
                              data-testid={`connectionProfiles.${index}.removeURL[${fieldIndex}]`}
                            >
                              <Tooltip title="Remove URL">
                                <RemoveIcon />
                              </Tooltip>
                            </IconButton>
                          )}
                          <IconButton
                            onClick={() => append("")}
                            data-testid={`connectionProfiles.${index}.addURL[${fieldIndex}]`}
                          >
                            <Tooltip title="Add More">
                              <AddIcon />
                            </Tooltip>
                          </IconButton>
                        </SortableItem>
                      ))}
                    </SortableContext>
                  </DndContext>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={6}>
                  <TcTextField
                    name={`connectionProfiles.${index}.deviceIP`}
                    label="Device Local IP *"
                    rules={{
                      required: "IP is required",
                      pattern: {
                        value: constants.IP_ADDRESS_REGEX,
                        message: "Invalid IP",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TcTextField
                    name={`connectionProfiles.${index}.port`}
                    label="Service Port *"
                    inputProps={{
                      sx: {
                        fontStyle: getFontStyle(
                          `connectionProfiles.${index}.port`
                        ),
                      },
                    }}
                    rules={{ required: "Service port is required" }}
                    type="number"
                  />
                </Grid>
              </>
            )}
          </Grid>
          {selectedDomain === DomainRegistered.NO && (
            <Card variant="outlined" sx={{ m: 1 }}>
              <CardContent sx={{ padding: 1 }}>
                <Grid container spacing={2}>
                  <>
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <Grid container alignItems="center">
                          <Grid item sx={{ paddingLeft: 1 }}>
                            <Controller
                              name="authType"
                              control={control}
                              render={({ field }) => (
                                <RadioGroup
                                  {...field}
                                  value={selectedAuthType}
                                  onChange={(event) =>
                                    onChangeAuthType(event.target.value)
                                  }
                                >
                                  <Grid container direction="row">
                                    <Grid item>
                                      <FormControlLabel
                                        value={AuthenticationType.MANUAL}
                                        control={<Radio />}
                                        label="Manual Credentials"
                                      />
                                    </Grid>
                                    <Grid item>
                                      <FormControlLabel
                                        value={AuthenticationType.PROFILE}
                                        control={<Radio />}
                                        label="Authentication Profile"
                                      />
                                    </Grid>
                                    {selectedProtocol.includes(
                                      Protocol.HTTP
                                    ) && (
                                      <Grid item>
                                        <FormControlLabel
                                          value={AuthenticationType.NO_AUTH}
                                          control={<Radio />}
                                          label="No Credentials"
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                </RadioGroup>
                              )}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                    {selectedProtocol === Protocol.SSH &&
                      selectedAuthType === AuthenticationType.PROFILE && (
                        <>
                          <Grid item xs={6}>
                            <TcSelectWithStringArray
                              name={`connectionProfiles.${index}.authenticationMethod`}
                              label="Authentication Method *"
                              rules={{
                                required: "Authentication Method is required",
                              }}
                              options={
                                constants.CONNECTION_PROFILE_AUTH_METHODS
                              }
                            />
                          </Grid>
                          {authMethod === AuthMethod.PASSWORD && (
                            <>
                              <Grid item xs={6}>
                                <TcTextField
                                  name={`connectionProfiles.${index}.username`}
                                  label="Username *"
                                  rules={{
                                    required: "Username is required",
                                  }}
                                ></TcTextField>
                              </Grid>
                              <TcPasswordTextField
                                name={`connectionProfiles.${index}.password`}
                                type="password"
                                passwordLabel="Password"
                                gridSize={6}
                                rules={{
                                  required: "Password is required",
                                }}
                                label="Password *"
                              ></TcPasswordTextField>
                            </>
                          )}
                          {authMethod === AuthMethod.KEY && (
                            <>
                              <Grid item xs={12}>
                                <FileUpload
                                  control={control}
                                  rules={{ required: "File is required" }}
                                  name={`keyFileName`}
                                  onFileUpload={async (file: File) =>
                                    await handleFileUploadClick(file)
                                  }
                                ></FileUpload>
                              </Grid>
                            </>
                          )}
                        </>
                      )}
                    {(selectedProtocol.includes(Protocol.HTTP) ||
                      selectedProtocol === Protocol.RDP) &&
                      selectedAuthType === AuthenticationType.PROFILE && (
                        <>
                          <Grid item xs={6}>
                            <TcTextField
                              name={`connectionProfiles.${index}.username`}
                              rules={{
                                required: "Username is required",
                              }}
                              label="Username *"
                            ></TcTextField>
                          </Grid>
                          <TcPasswordTextField
                            name={`connectionProfiles.${index}.password`}
                            type="password"
                            passwordLabel="Password"
                            gridSize={6}
                            rules={{
                              required: "Password is required",
                            }}
                            label="Password *"
                          ></TcPasswordTextField>
                        </>
                      )}
                  </>
                </Grid>
              </CardContent>
            </Card>
          )}
          <Grid container spacing={2} pt={1}>
            <Grid item>
              <Button
                size="small"
                type="submit"
                disabled={
                  Object.keys(errors).length !== 0 || !isValid || !isDirty
                }
                onClick={handleSaveProfile}
                fullWidth
                variant="contained"
                color="info"
              >
                Save Profile
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                color="info"
                onClick={onDeleteProfile}
              >
                Delete Profile
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default AddConnectionProfile;
